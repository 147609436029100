import nasPalette from './default/palette'

export default {
  palette: nasPalette,

  brandId: 'nas',

  extendedMenuTheme: 'default',
  mainMenuTheme: 'default',
  teaserBlockTheme: 'default',
  topBannerTheme: 'default',

  buttonIcon: {
    containedSolid: false,
  },
  circularProgress: {
    sizeContained: '1em',
    sizeDefault: '1em',
  },
  logo: {
    url: '/v1/img/nas-chief-horizontal.svg',
    height: 80,
    polychromatic: true,
  },
  background: {
    url: '/v1/img/nas-background.jpg',
  },
  pointsBadge: {
    color: nasPalette.secondary.main,
    backgroundColor: nasPalette.secondary.contrastText,
    borderRadius: 0,
    border: `1px solid ${nasPalette.secondary.main}`,
    padding: '2.5px 5px 1.5px',
    lineHeight: 1,
  },
  pointsBadgeNegative: {
    color: nasPalette.secondary.contrastText,
    backgroundColor: nasPalette.secondary.main,
    border: `1px solid ${nasPalette.secondary.contrastText}`,
  },
  maxWidth: 1440,
}
